<template>
  <v-app>
    <v-navigation-drawer
      v-if="usePanel"
      v-model="drawer"
      app
      color="grey darken-3"
    >
      <SideBar></SideBar>
    </v-navigation-drawer>

    <v-app-bar v-if="usePanel" app flat dense>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text small @click="logout">
        Cerrar sesión
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view @usePanel="changeLayout"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import SideBar from './components/SideBar.vue';
import axios from 'axios';

export default {
  name: 'App',

  data: () => ({
    drawer: null,
    usePanel: true,
  }),

  components: {
    SideBar,
  },

  methods: {
    changeLayout(usePanel) {
      this.usePanel = usePanel;
    },
    async logout() {
      try {
        //await axios.get(process.env.VUE_APP_API_URL + '/usuarios/logout');
	await axios.post('/authelia/api/logout');

        //this.$router.push({ name: 'Login' });
        window.location.href = 'https://crm.alistelab.com/authelia/?rd=https%3A%2F%2Fcrm.alistelab.com';
      } catch (error) {
        alert('Error - No se ha podido cerrar sesión');
      }
    },
  },
};
</script>
