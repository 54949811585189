import Vue from 'vue';
import VueRouter from 'vue-router';
import Inicio from '../views/Inicio.vue';
import auth from './auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio,
    beforeEnter: auth.usuario,
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: auth.public,
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: () => import('../views/Clientes.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/clientes/:id',
    name: 'Cliente',
    component: () => import('../components/Clientes/Cliente.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/profesores',
    name: 'Profesores',
    component: () => import('../views/Profesores.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/profesores/:id',
    name: 'Profesor',
    component: () => import('../components/Profesores/Profesor.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('../views/Servicios.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/servicios/:id',
    name: 'Servicio',
    component: () => import('../components/Servicios/Servicio.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/actividades',
    name: 'Actividades',
    component: () => import('../views/Actividades.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/inscripciones',
    name: 'Inscripciones',
    component: () => import('../views/Inscripciones.vue'),
  },
  {
    path: '/actividad/:id',
    name: 'Actividad',
    component: () => import('../components/Actividades/Actividad.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/contrataciones',
    name: 'Contrataciones',
    component: () => import('../views/Contrataciones.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/contrataciones/:id',
    name: 'Contratacion',
    component: () => import('../components/Contrataciones/Contratacion.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/recibos',
    name: 'Recibos',
    component: () => import('../views/Recibos.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/recibos/:id',
    name: 'Recibo',
    component: () => import('../components/Recibos/Recibo.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: () => import('../views/Facturas.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/gastos',
    name: 'Gastos',
    component: () => import('../views/Gastos.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import('../views/Usuarios.vue'),
    beforeEnter: auth.usuario,
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import('../views/Log.vue'),
    beforeEnter: auth.usuario,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
