<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-img src="../assets/logo-300.png"></v-img>
      </v-list-item-content>
    </v-list-item>

    <v-list dense nav>
      <v-list-item v-for="item in items" :key="item.title" link :to="item.href">
        <v-list-item-icon>
          <v-icon class="white--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: 'Inicio', icon: 'mdi-home', href: '/' },
        {
          title: 'Clientes',
          icon: 'mdi-card-account-details',
          href: '/clientes',
        },
        {
          title: 'Profesores',
          icon: 'mdi-school',
          href: '/profesores',
        },
        { title: 'Servicios', icon: 'mdi-room-service', href: '/servicios' },
        {
          title: 'Actividades',
          icon: 'mdi-calendar-month',
          href: '/actividades',
        },
        {
          title: 'Contrataciones',
          icon: 'mdi-handshake',
          href: '/contrataciones',
        },
        { title: 'Recibos', icon: 'mdi-receipt', href: '/recibos' },
        { title: 'Facturas', icon: 'mdi-billboard', href: '/facturas' },
        { title: 'Gastos', icon: 'mdi-cart-variant', href: '/gastos' },
        //{ title: 'Usuarios', icon: 'mdi-account-group', href: '/usuarios' },
        //{ title: 'Log', icon: 'mdi-bug', href: '/log' },
      ],
    };
  },
};
</script>
