<template>
  <div>
    <p>
      Bienvenido al panel de control de Alistelab.
    </p>
    <v-spacer></v-spacer>
    <h1>
      Notificaciones
    </h1>

    <div v-show="loadingNotificaciones">
      <v-progress-linear indeterminate></v-progress-linear>
      <p class="text-center text--secondary">Cargando...</p>
    </div>

    <p v-show="errorNotificaciones" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo las notificaciones
    </p>

    <div v-if="!loadingNotificaciones && !errorNotificaciones">
      <v-alert
        v-for="(notificacion, index) in notificaciones"
        :key="notificacion._id"
        :type="notificacion.nivel"
        elevation="5"
        border="left"
        transition="scale-transition"
      >
        {{ notificacion.mensaje }}

        <template v-slot:append>
          <v-btn icon @click="closeNotification(index)">
            <v-icon>mdi-eye-off</v-icon>
          </v-btn>
        </template>
      </v-alert>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Inicio',
  data() {
    return {
      notificaciones: [],
      errorNotificaciones: false,
      loadingNotificaciones: true,
    };
  },
  methods: {
    async getNotificaciones() {
      try {
        this.loadingNotificaciones = true;
        const res = await axios.get(process.env.VUE_APP_API_URL + '/notificaciones');
        this.notificaciones = res.data;
        this.loadingNotificaciones = false;
      } catch (error) {
        this.loadingNotificaciones = false;
        this.errorNotificaciones = true;
      }
    },
    async closeNotification(index) {
      try {
        await axios.post(process.env.VUE_APP_API_URL + '/notificaciones/eliminar', {
          id: this.notificaciones[index]._id,
        });
        this.notificaciones.splice(index, 1);
      } catch (error) {
        alert('Error - No se ha podido desmarcar la notificación');
      }
    },
  },
  mounted() {
    this.getNotificaciones();
    this.$emit('usePanel', true);
  },
};
</script>
