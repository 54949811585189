import axios from 'axios';

export default {
  async usuario(to, from, next) {
    try {
      await axios.get(process.env.VUE_APP_API_URL + '/notificaciones');

      next();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          //return next('/login');
          window.location.href = 'https://crm.alistelab.com/authelia/?rd=https%3A%2F%2Fcrm.alistelab.com';
        }
      }

      //alert('El servidor no responde');

      next(false);
    }
  },
  async public(to, from, next) {
    try {
      await axios.get(process.env.VUE_APP_API_URL + '/notificaciones');

      next('/');
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return next();
        }
      }

      alert('El servidor no responde');

      next(false);
    }
  },
};
